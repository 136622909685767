<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                Access to calls
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Add area and group"
              @click="$router.push({name: 'admin.access-calls.create'})"
            ><i data-feather="plus" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <div class="card card--table">
              <div class="card-header d-flex align-items-center justify-content-between">
                <div>
                  <h4><strong>{{ access.length }}</strong> access</h4>
                </div>
                <div>
                  <a
                    href="#"
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Export to Excel"
                  ><i data-feather="file" /></a>
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Table settings"
                  ><a
                    href="#"
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasTableSettings"
                    aria-controls="offcanvasTableSettings"
                  ><i data-feather="settings" /></a></span>
                </div>
              </div>

              <div class="table-responsive-sm">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th class="text-end">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="a in access">
                      <tr :key="a.id">
                        <td>{{ a.name }}</td>
                        <td class="text-end">
                          <span
                            class="dis-ib"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Edit area and group"
                          ><a
                            class="btn btn-icon rounded-circle btn-flat-dark"
                            @click="$router.push({name: 'admin.access-calls.edit', params: { id: a.id }}, )"
                          ><i data-feather="edit-3" /></a></span>
                          <span
                            class="dis-ib"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Delete area & group"
                            @click="showDeleteModal(a.id)"
                          ><a
                            href="#"
                            class="btn btn-icon rounded-circle btn-flat-danger"
                            data-bs-toggle="modal"
                            data-bs-target="#modalDeleteWithInput"
                          ><i data-feather="trash-2" /></a></span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between">
                  <Paginator
                    :total="totalAccess"
                    :store="'access/fetch'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Paginator from '../../partials/Paginator.vue'

export default {
  components: {
    Paginator,
  },
  computed: {
    ...mapGetters({
      access: 'access/accesses',
      totalAccess: 'access/totalAccess',
    }),
  },
  async mounted() {
    await this.$store.dispatch('access/filter', this.$store.getters['filters/filters'])
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'access', url: `${Vue.prototype.$groupUrl}/access/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
